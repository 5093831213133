import React from 'react';
import './Footer.css'

const Footer = () => {
    return (
        <>
            <div>
                <br/>
                <footer className="footer">
                    <p>Crafted with precision by Angie & Lyuda</p>
                </footer>
            </div>
        </>
        
    );
};

export default Footer;